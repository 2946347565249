import React, { Component } from "react";
import ons from "onsenui";
import * as Ons from "react-onsenui";
import ReactDOM from "react-dom";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import "bootstrap/dist/css/bootstrap.css";
import { checkSession } from "../containers/Api";

class Home extends Component {
  state = {
    isLoggedIn: false,
    user_info: {
      uid: null,
      user_name: null,
      auth_token: null
    },
    loading: true,
    deviceImei: null
  };

  async componentDidMount() {
    document.title = "Home - ConectaAppTech";
    const isLoggedIn = this.getLocalVar("auth_token");
    if (!isLoggedIn) {
      this.setState({ isLoggedIn: false, loading: false });
    } else {
      const auth_token = localStorage.getItem("auth_token");
      const uid = localStorage.getItem("user_id");
      const user_name = localStorage.getItem("user_name");
      const _checkSession = await checkSession(auth_token, uid);
      if (_checkSession.data.error && !_checkSession.data.isLoggedIn) {
        this.doLogout();
      }
      //persiste response
      const imei = this.getDeviceImei();
      this.isGpsEnabled();
      //this.isGpsEnabled();
      this.setState({
        loading: false,
        deviceImei: imei,
        isLoggedIn: true,
        user_info: { uid: uid, user_name: user_name, auth_token: auth_token }
      });
    }
  }

  isGpsEnabled() {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf("android") > -1;
    if (isAndroid) {
      try {
        if (!window.isGpsEnabled()) {
          try {
            ons.notification.alert({
              message: "Localização desabilitada, ative.",
              title: "ConectaAppTech",
              buttonLabel: "OK",
              animation: "default", // or 'none'
              callback: () => {
                window.openGpsConfig();
              }
            });
          } catch (error) {
            ons.notification.alert("Erro: " + error);
          }
        }
      } catch (error) {
        return false;
      }
    }
  }

  getLocation() {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf("android") > -1;
    if (isAndroid) {
      try {
        const local = window.getLocation();
        if (local !== null) {
          ons.notification.alert(
            local["lat"] +
              " - " +
              local["long"] +
              " - " +
              local["data"] +
              " - Bateria:" +
              local["bateria"]
          );
        } else {
          ons.notification.alert("não veio nada");
        }
      } catch (error) {
        ons.notification.alert(error);
      }
    } else {
      return "notandroid";
    }
  }

  getDeviceImei() {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf("android") > -1;
    if (isAndroid) {
      try {
        const imei = window.getImei();
        return imei;
      } catch (error) {
        return false;
      }
    } else {
      return "Não é android";
    }
  }

  getLocalVar(_var) {
    if (localStorage.getItem(_var)) {
      return localStorage.getItem(_var);
    } else {
      return false;
    }
  }

  doLogout() {
    localStorage.clear();
    this.setState({
      isLoggedIn: false,
      loading: false,
      user_info: { uid: null, user_name: null, auth_token: null }
    });
  }

  render() {
    const { user_info, loading, isLoggedIn, deviceImei } = this.state;

    if (loading) {
      return (
        <span
          className="loader_"
          style={{ width: "7em", height: "7em", marginLeft: "-2%" }}
        />
      );
    }

    if (!isLoggedIn) {
      return <Redirect to="/login" />;
    }

    return (
      <Ons.Page
        renderToolbar={() => (
          <Ons.Toolbar>
            <div className="center">ConectaAppTech</div>
            <div className="right">
              <Ons.ToolbarButton onClick={() => window.location.reload()}>
                <Ons.Icon icon="fa-refresh" />
              </Ons.ToolbarButton>
              <Ons.ToolbarButton onClick={this.doLogout.bind(this)}>
                <Ons.Icon icon="fa-sign-out" />
              </Ons.ToolbarButton>
            </div>
          </Ons.Toolbar>
        )}
      >
        <Ons.Card>
          <div className="content">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  <h3>
                    Técnico: {user_info.user_name} - {user_info.uid}
                  </h3>
                </li>
              </ol>
            </nav>
            <center style={{ padding: "5px" }}>
              <h3 className="page-header">{new Date().toLocaleString()}</h3>
            </center>
            <section style={{ marginTop: "10px" }} align="center">
              <Link to="/chamados">
                <Ons.Button modifier="large--cta">Ver Chamados</Ons.Button>
              </Link>
            </section>
            <section style={{ marginTop: "10px" }} align="center">
              {user_info.uid == 56 || user_info.uid == 199 ? (
                <Link to="/despachar">
                  <Ons.Button modifier="large--cta">
                    Despachar Chamados
                  </Ons.Button>
                </Link>
              ) : null}
            </section>
          </div>
        </Ons.Card>
      </Ons.Page>
    );
  }
}

export default Home;
