import axios from "axios";

const queryString = require("query-string");

export const findAll = async (idtec, auth_token) =>
  await axios.post(
    "https://sla.conectacopiadoras.com.br/api_chamados/getChamados.php",
    queryString.stringify({ idtec: idtec, auth_token: auth_token })
  );

export const findOne = async (idtec, auth_token, numcha) =>
  await axios.post(
    "https://sla.conectacopiadoras.com.br/api_chamados/getChamados.php",
    queryString.stringify({
      idtec: idtec,
      auth_token: auth_token,
      numcha: numcha
    })
  );

export const checkSession = async (auth_token, user_id) =>
  await axios.post(
    "https://sla.conectacopiadoras.com.br/api_chamados/index.php",
    queryString.stringify({
      auth_token: auth_token,
      idtec: user_id,
      tag: "checkSession"
    })
  );
export const setDataLido = async (auth_token, user_id, numcha) =>
  await axios.post(
    "https://sla.conectacopiadoras.com.br/api_chamados/index.php",
    queryString.stringify({
      auth_token: auth_token,
      idtec: user_id,
      numcha: numcha,
      tag: "setDataLido"
    })
  );
export const setStartServiceTime = async (auth_token, user_id, numcha) =>
  await axios.post(
    "https://sla.conectacopiadoras.com.br/api_chamados/index.php",
    queryString.stringify({
      auth_token: auth_token,
      idtec: user_id,
      numcha: numcha,
      tag: "setStartServiceTime"
    })
  );
export const dispatchChamado = async (auth_token, tectodispatch, user_id, numcha) =>
  await axios.post(
    "https://sla.conectacopiadoras.com.br/api_chamados/index.php",
    queryString.stringify({
      auth_token: auth_token,
      idtec: user_id,
      tectodispatch: tectodispatch,
      numcha: numcha,
      tag: "despachaChamado"
    })
  );
export const getPendencias = async (auth_token, user_id, numcha) =>
  await axios.post(
    "https://sla.conectacopiadoras.com.br/api_chamados/index.php",
    queryString.stringify({
      auth_token: auth_token,
      idtec: user_id,
      numcha: numcha,
      tag: "getPendencias"
    })
  );
export const login = async (email, senha) =>
  await axios.post(
    "https://sla.conectacopiadoras.com.br/api_chamados/index.php",
    queryString.stringify({ email: email, password: senha, tag: "login" })
  );
export const createPendencia = async (
  auth_token,
  user_id,
  numcha,
  descricao,
  cpb,
  ccolor,
  nserie,
  setor
) =>
  await axios.post(
    "https://sla.conectacopiadoras.com.br/api_chamados/index.php",
    queryString.stringify({
      auth_token: auth_token,
      idtec: user_id,
      numcha: numcha,
      descricao: descricao,
      cpb: cpb,
      ccolor: ccolor,
      nserie: nserie,
      setor: setor,
      tag: "createPendencia"
    })
  );
export const finalizarChamado = async (
  auth_token,
  user_id,
  numcha,
  descricao,
  cpb,
  ccolor,
  nserie,
  setor,
  lat,
  long,
  bateria
) =>
  await axios.post(
    "https://sla.conectacopiadoras.com.br/api_chamados/index.php",
    queryString.stringify({
      auth_token: auth_token,
      idtec: user_id,
      numcha: numcha,
      descricao: descricao,
      cpb: cpb,
      ccolor: ccolor,
      nserie: nserie,
      setor: setor,
      lat: lat,
      long: long,
      bateria: bateria,
      tag: "finalizaChamado"
    })
  );
