import React, { Component } from 'react';
import {Redirect} from 'react-router';
import ons, { createAlertDialog } from 'onsenui';
import * as Ons from 'react-onsenui';
import 'bootstrap/dist/css/bootstrap.css';
import 'onsenui/css/onsenui.css';
import { Link } from 'react-router-dom'
import 'onsenui/css/onsen-css-components.css';
import {checkSession,findOne,dispatchChamado} from '../containers/Api';
import {
    Form
  } from 'reactstrap';

export default class Despachar extends Component {
    
    state = {
        isLoggedIn: false,
        loading: true,
        chamado: null,
        user_info : {
            uid: null,
            user_name: null,
            auth_token: null,
        },
        index: 0,
        renderDespacho: false
    }

    async componentDidMount(){
        
        const isLoggedIn = this.getLocalVar("auth_token");
        if(!isLoggedIn){
            this.setState({isLoggedIn:false,loading:false});
        }else{
            const auth_token = localStorage.getItem("auth_token");
            const uid = localStorage.getItem("user_id");
            const user_name = localStorage.getItem("user_name");
            const _checkSession = await checkSession(auth_token,uid);
            if(_checkSession.data.error && !_checkSession.data.isLoggedIn)
            {
                this.doLogout();
            }
        
            try {
                document.title = "Despachar Chamado";
                this.isGpsEnabled();
                this.setState({loading:false,isLoggedIn:true,user_info:{uid:uid,user_name:user_name,auth_token:auth_token}});
            }catch (error) {
                this.doLogout(); 
            }
        }
    }

    isGpsEnabled() {
        const ua = navigator.userAgent.toLowerCase();
        const isAndroid = ua.indexOf("android") > -1;
        if (isAndroid) {
          try {
            if (!window.isGpsEnabled()) {
              try {
                ons.notification.alert({
                  message: "Localização desabilitada, ative.",
                  title: "ConectaAppTech",
                  buttonLabel: "OK",
                  animation: "default", // or 'none'
                  callback: () => {
                    window.openGpsConfig();
                  }
                });
              } catch (error) {
                ons.notification.alert("Erro: " + error);
              }
            }
          } catch (error) {
            return false;
          }
        }
      }

    getLocalVar(_var){
        if(localStorage.getItem(_var)){
            return localStorage.getItem(_var);
        }else{
            return false;
        }
    }

    doLogout(){
        localStorage.clear();
        this.setState({isLoggedIn:false,chamado:null,renderDespacho:false,loading:false,user_info : { uid: null, user_name: null, auth_token: null }} );
    }

    handleFocus(event) {
        event.target.select();
    }

    renderFormCreateTask(numcha){
        const {chamado} = this.state;
        return (
            <div>
                <Ons.Card>
                    <h4>Despacho Chamado nº {chamado.numcha}</h4>
                    <h6>Confirme se é o chamado que deseja despachar e escolha o técnico.</h6>
                    <hr />
                    <Form>
                        <div className="form-group">
                            <label for="nomcli">Nome Cliente</label>
                            <input className="form-control" type="text" readOnly="true" name="nomcli" defaultValue={chamado.nomcli} ref={(nomcli) => { this.nomcli = nomcli; }} />
                        </div>
                        <div className="form-group">
                            <label for="descricao">Descrição</label>
                            <textarea className="form-control" rows="5" readOnly="true" name="descricao" ref={(descricao) => { this.descricao = descricao; }}>{chamado.descricao}</textarea>
                        </div>
                        <div className="form-group">
                            <label for="tectodispatch">Data Chamado</label>
                            <input className="form-control" type="text" readOnly="true" name="datcha" ref={(datcha) => { this.datcha = datcha; }} defaultValue={chamado.datcha} />
                        </div>                        
                        <div className="form-group">
                            <label for="datcha">Despachar para</label>
                            <select className="form-control" name="tecstodispatch" ref={(tecstodispatch) => {this.tecstodispatch = tecstodispatch}}>
                                <option selected value="">Escolha um técnico</option>
                                {chamado.tecsTodispatch.map((tectodispatch, index) => 
                                (
                                    <option value={tectodispatch.uid}>{tectodispatch.uid}-{tectodispatch.name}</option>
                                ))}
                            </select>
                        </div>
                        <hr />
                        <center>
                            <Ons.Button type="submit" className="btn-success" onClick={this.onSubmit}>Enviar</Ons.Button>
                            <Ons.Button style={{marginLeft:'5px'}} onClick={this.cancelDespacho.bind(this)}>Cancelar</Ons.Button>
                        </center>
                    </Form>
                </Ons.Card>
            </div>
        )
    }

    async buscaChamado(){
        if(document.getElementById("txtNumCha").value == "" || document.getElementById("txtNumCha").value.length < 6 || isNaN(document.getElementById("txtNumCha").value))
        {
            ons.notification.alert("Informe um número de chamado!");
            this.clearInput();
        }else{
            const _chamado = await findOne(localStorage.getItem("user_id"),localStorage.getItem("auth_token"),document.getElementById("txtNumCha").value);
            //console.log(_chamado.data);
            if(_chamado.data.error && !_chamado.data.isLoggedIn.isLoggedIn)
            {
                this.doLogout();
            }

            try {
                this.setState({chamado:_chamado.data.chamados[0]});
                this.createDespacho();                
            } catch (error) {
                this.clearInput();
                ons.notification.alert("Erro na consulta ou número de chamado inválido");
            }
        }
    }

    onSubmit = async (e) =>{
        e.preventDefault();
        if(this.tecstodispatch.value === '' || isNaN(this.tecstodispatch.value)){
            ons.notification.alert("Selecione um técnico");
        }else{
            let _dispatchChamado = await dispatchChamado(localStorage.getItem("auth_token"),this.tecstodispatch.value,localStorage.getItem("user_id"),document.getElementById("txtNumCha").value);
            if(_dispatchChamado.data.error || _dispatchChamado.data === 'Invalid Request')
            {
                ons.notification.alert("Requisição não processada ou dados inválidos.");
                this.clearInput();
            }else{
                ons.notification.alert({message: "Chamado despachado com sucesso.",
                title: 'ConectaAppTech',
                buttonLabel: 'OK',
                animation: 'default', // or 'none'
                callback: () => {
                    window.location.href = '/';
                }});
            }
        }
    }

    clearInput(){
        document.getElementById("txtNumCha").value = "";
        this.cancelDespacho();
    }

    createDespacho(){
        this.setState({renderDespacho:true});
    }

    cancelDespacho(){
        this.setState({renderDespacho:false});
    }
        
    render(){

        const {user_info,loading,isLoggedIn, renderDespacho, chamado} = this.state;
        
        if(loading)
        {
            return <span className="loader_" style={{width:'7em',height:'7em',marginLeft:'-2%'}}></span>;
        }

        if(!isLoggedIn){
           return <Redirect to='/login' />
        }

        return(
            <Ons.Page renderModal={() =>
                <Ons.Toolbar>
                    <div className="left">
                    <Link to={{
                                pathname:'/'
                            }}
                        style={{color:'black'}}>
                            <Ons.ToolbarButton>
                                <Ons.Icon icon="fa-arrow-left" />
                            </Ons.ToolbarButton>
                        </Link>
                    </div>
                    <div className="center">
                        <span style={{fontSize:'16px'}}>Despachar Chamado</span>
                    </div>
                </Ons.Toolbar>
            }>
            <div className="content" style={{marginTop:'60px'}}>
                <Ons.Card>
                    <section style={{textAlign: 'center'}}>
                        <p>
                        <Ons.SearchInput inputId="txtNumCha" placeholder='Nº Chamado' />
                        <br /><br />
                        <Ons.Button onClick={this.buscaChamado.bind(this)}>
                            Buscar
                        </Ons.Button>
                        &nbsp;
                        <Ons.Button className="btn-default" onClick={this.clearInput.bind(this)}>
                            Limpar
                        </Ons.Button>
                        </p>
                    </section>
                </Ons.Card>
                {(renderDespacho)? this.renderFormCreateTask(chamado.numcha) : null}
            </div>
            </Ons.Page>
        )
    }
}