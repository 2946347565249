import React, { Component } from "react";
import { Redirect } from "react-router";
import ons, { createAlertDialog } from "onsenui";
import * as Ons from "react-onsenui";
import "bootstrap/dist/css/bootstrap.css";
import "onsenui/css/onsenui.css";
import { Link } from "react-router-dom";
import "onsenui/css/onsen-css-components.css";
import { checkSession, finalizarChamado, findOne } from "../containers/Api";
import { Form } from "reactstrap";

export default class Finalizar extends Component {
  state = {
    isLoggedIn: false,
    loading: true,
    chamado: null,
    canEndChamado: null,
    userMustAttach: null,
    hasAttachment: null,
    user_info: {
      uid: null,
      user_name: null,
      auth_token: null
    },
    qtcantgps: 0,
    descricao: null,
    cpb: 0,
    ccolor: 0,
    nserie: null,
    setor: null
  };

  async componentDidMount() {
    const isLoggedIn = this.getLocalVar("auth_token");
    if (!isLoggedIn) {
      this.setState({ isLoggedIn: false, loading: false });
    } else {
      const auth_token = localStorage.getItem("auth_token");
      const uid = localStorage.getItem("user_id");
      const user_name = localStorage.getItem("user_name");
      const _checkSession = await checkSession(auth_token, uid);
      if (_checkSession.data.error && !_checkSession.data.isLoggedIn) {
        this.doLogout();
      }

      try {
        let {
          chamado,
          userMustAttach,
          hasAttachment
        } = this.props.location.state;
        document.title = "Finalizar Chamado Nº" + chamado.numcha;
        this.isGpsEnabled();

        if (!chamado.data_inicio_atendimento || chamado.temPendenciaAtiva) {
          window.location.href = "/chamados";
        }

        let chamadoInfoUpdated = await findOne(uid, auth_token, chamado.numcha);
        chamado = chamadoInfoUpdated.data.chamados[0];

        hasAttachment = chamado.anexos;

        this.setState({
          loading: false,
          isLoggedIn: true,
          chamado: chamado,
          userMustAttach: userMustAttach,
          hasAttachment: hasAttachment,
          user_info: { uid: uid, user_name: user_name, auth_token: auth_token },
          setor: chamado.setor,
          nserie: chamado.nserie
        });
      } catch (error) {
        this.doLogout();
      }
    }
  }

  isGpsEnabled() {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf("android") > -1;
    if (isAndroid) {
      try {
        if (!window.isGpsEnabled()) {
          try {
            ons.notification.alert({
              message: "Localização desabilitada, ative.",
              title: "ConectaAppTech",
              buttonLabel: "OK",
              animation: "default", // or 'none'
              callback: () => {
                window.openGpsConfig();
              }
            });
          } catch (error) {
            ons.notification.alert("Erro: " + error);
          }
        }
      } catch (error) {
        return false;
      }
    }
  }

  getLocation() {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf("android") > -1;
    if (isAndroid) {
      try {
        const local = window.getLocation();
        if (local !== null) {
          return local;
        }
      } catch (error) {
        ons.notification.alert(error);
      }
    } else {
      return "notandroid";
    }
  }

  getLocalVar(_var) {
    if (localStorage.getItem(_var)) {
      return localStorage.getItem(_var);
    } else {
      return false;
    }
  }

  doLogout() {
    localStorage.clear();
    this.setState({
      isLoggedIn: false,
      loading: false,
      user_info: { uid: null, user_name: null, auth_token: null }
    });
  }

  enviaSubmit = async (e) => {
    try {
      e.preventDefault();
      if (
        this.state.descricao === "" ||
        this.state.cpb === "" ||
        this.state.ccolor === "" ||
        this.state.nserie === "" ||
        this.state.setor === ""
      ) {
        ons.notification.alert("Por favor, preencha todos os dados.");
      } else {

        let location = this.getLocation();
        let continuar = false;
        let lat = "";
        let long = "";
        let dataL = "";
        let bateria = "";
        
        try {
          
          if (location == "notandroid") {
            continuar = true;
          } else {
            if (location["data"] == "naotem") {
              ons.notification.alert(
                "Aguarde 10 segundos e aperte finalizar novamente."
              );
              let qtcantgps = this.state.qtcantgps + 1;
              this.setState({ qtcantgps: qtcantgps });

              if (qtcantgps > 3) {
                continuar = true;
              }
            } else {
              lat = location["lat"];
              long = location["long"];
              dataL = location["data"];
              bateria = location["bateria"];
              continuar = true;
            }
          }
        } catch (error) {
          console.log(error);
          console.log("erro gps");
        }

        if (continuar) {
          if (window.confirm("Tem certeza?")) {
            this.setState({ loading: true });
            const auth_token = localStorage.getItem("auth_token");
            const uid = localStorage.getItem("user_id");
            const { chamado } = this.state;
            try {
              const finalizaChamado = await finalizarChamado(
                auth_token,
                uid,
                chamado.numcha,
                this.state.descricao,
                this.state.cpb,
                this.state.ccolor,
                this.state.nserie,
                this.state.setor,
                lat,
                long,
                bateria
              );
              if (finalizaChamado.data.error) {
                ons.notification.alert({
                  message: "Erro ao finalizar chamado. Tente novamente",
                  title: "ConectaAppTech",
                  buttonLabel: "OK",
                  animation: "default", // or 'none'
                  callback: () => {
                    window.location.reload();
                  }
                });
              } else {
                ons.notification.alert({
                  message: finalizaChamado.data.msg,
                  title: "ConectaAppTech",
                  buttonLabel: "OK",
                  animation: "default", // or 'none'
                  callback: () => {
                    window.location.href = "/chamados";
                  }
                });
              } 
            } catch (error) {
              console.log("erro envio");
              console.log(error);
            }
          }
        }
      } 
    } catch (error) {
      console.log(error)
    }
  };

  handleFocus(event) {
    event.target.select();
  }

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  };

  render() {
    const {
      user_info,
      loading,
      hasAttachment,
      userMustAttach,
      isLoggedIn,
      chamado,
      canEndChamado
    } = this.state;

    let disableEndButton =
      hasAttachment === false && userMustAttach === 1 ? true : false;

    if (loading) {
      return (
        <span
          className="loader_"
          style={{ width: "7em", height: "7em", marginLeft: "-2%" }}
        ></span>
      );
    }

    if (!isLoggedIn) {
      return <Redirect to="/login" />;
    }

    return (
      <Ons.Page
        renderModal={() => (
          <Ons.Toolbar>
            <div className="left">
              <Link
                to={{
                  pathname: `/chamado/${chamado.numcha}`,
                  state: {
                    chamado: chamado
                  }
                }}
                style={{ color: "black" }}
              >
                <Ons.ToolbarButton>
                  <Ons.Icon icon="fa-arrow-left" />
                </Ons.ToolbarButton>
              </Link>
            </div>
            <div className="center">
              <span style={{ fontSize: "16px" }}>
                Finalizar Chamado Nº {chamado.numcha}
              </span>
            </div>
          </Ons.Toolbar>
        )}
      >
        <div className="content" style={{ marginTop: "60px" }}>
          <Ons.Card>
            <h4>Finalizar Chamado</h4>
            <h5>
              Preencha os dados, confirme se o número de série está correto e
              clica em finalizar.
            </h5>

            <h5>
              Lembre-se! O chamado físico, páginas de teste e status devem ser
              entregues a área técnica.
            </h5>

            <hr />
            <Form>
              <div className="form-group">
                <label>Descrição</label>
                <textarea
                  className="form-control"
                  rows="5"
                  name="descricao"
                  onChange={this.handleChange}
                ></textarea>
              </div>
              <div className="form-group">
                <label for="cpb">Contador PB</label>
                <input
                  className="form-control"
                  type="number"
                  name="cpb"
                  id="cpb"
                  defaultValue="0"
                  min="0"
                  onFocus={this.handleFocus}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label for="ccolor">Contador Color</label>
                <input
                  className="form-control"
                  type="number"
                  name="ccolor"
                  id="ccolor"
                  defaultValue="0"
                  min="0"
                  onFocus={this.handleFocus}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label for="ccolor">Setor</label>
                <input
                  className="form-control"
                  type="text"
                  name="setor"
                  id="setor"
                  onChange={this.handleChange}
                  defaultValue={chamado.setor}
                />
              </div>
              <div className="form-group">
                <label for="ccolor">Nº Série</label>
                <input
                  className="form-control"
                  type="text"
                  name="nserie"
                  id="nserie"
                  onChange={this.handleChange}
                  defaultValue={chamado.nserie}
                />
              </div>

              <hr />
              {disableEndButton ? (
                <center>
                  <a
                    target="_blank"
                    href={
                      "https://sla.conectacopiadoras.com.br/api_chamados/uploadAttachment.php?idtec=" +
                      user_info.uid +
                      "&auth_token=" +
                      user_info.auth_token +
                      "&numcha=" +
                      chamado.numcha
                    }
                  >
                    Upload Anexos
                  </a>
                  <br />
                  <hr />
                </center>
              ) : (
                ""
              )}
              <center>
                <Ons.Button
                  type="submit"
                  className="btn-success"
                  onClick={this.enviaSubmit.bind(this)}
                  disabled={disableEndButton}
                >
                  Finalizar
                </Ons.Button>
              </center>
            </Form>
          </Ons.Card>
        </div>
      </Ons.Page>
    );
  }
}
