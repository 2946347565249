import React, { Component } from "react";
import { Redirect } from "react-router";
import ons, { createAlertDialog } from "onsenui";
import * as Ons from "react-onsenui";
import "bootstrap/dist/css/bootstrap.css";
import "onsenui/css/onsenui.css";
import { Link } from "react-router-dom";
import "onsenui/css/onsen-css-components.css";
import {
  checkSession,
  setStartServiceTime,
  setDataLido,
  findOne
} from "../containers/Api";

export default class Chamado extends Component {
  state = {
    isLoggedIn: false,
    loading: true,
    chamado: null,
    alertDialogStartServiceShown: false,
    serviceStarted: false,
    user_info: {
      uid: null,
      user_name: null,
      auth_token: null
    }
  };

  async componentDidMount() {
    const isLoggedIn = this.getLocalVar("auth_token");
    if (!isLoggedIn) {
      this.setState({ isLoggedIn: false, loading: false });
    } else {
      const auth_token = localStorage.getItem("auth_token");
      const uid = localStorage.getItem("user_id");
      const user_name = localStorage.getItem("user_name");
      const _checkSession = await checkSession(auth_token, uid);
      if (_checkSession.data.error && !_checkSession.data.isLoggedIn) {
        this.doLogout();
      }
      const mustAttach = _checkSession.data.mustAttach;
      try {
        let { chamado } = this.props.location.state;

        let temAnexos = chamado.anexos;
        document.title = "Chamado Nº" + chamado.numcha;

        //grava data lido
        if (chamado.data_lido === false) {
          const gravaDataLido = await setDataLido(
            auth_token,
            uid,
            chamado.numcha
          );
          if (gravaDataLido.data.error && !gravaDataLido.data.isLoggedIn) {
            this.doLogout();
          }
        }

        //persiste response
        this.isGpsEnabled();
        this.setState({
          loading: false,
          isLoggedIn: true,
          temPendenciaAtiva: chamado.temPendenciaAtiva,
          serviceStarted: chamado.data_inicio_atendimento,
          hasAttachment: temAnexos,
          userMustAttach: mustAttach,
          chamado: chamado,
          user_info: {
            uid: uid,
            user_name: user_name,
            auth_token: auth_token
          }
        });
      } catch (error) {
        this.doLogout();
      }
    }
  }

  isGpsEnabled() {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf("android") > -1;
    if (isAndroid) {
      try {
        if (!window.isGpsEnabled()) {
          try {
            ons.notification.alert({
              message: "Localização desabilitada, ative.",
              title: "ConectaAppTech",
              buttonLabel: "OK",
              animation: "default", // or 'none'
              callback: () => {
                window.openGpsConfig();
              }
            });
          } catch (error) {
            ons.notification.alert("Erro: " + error);
          }
        }
      } catch (error) {
        return false;
      }
    }
  }

  getLocation() {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf("android") > -1;
    if (isAndroid) {
      try {
        const local = window.getLocation();
        if (local !== null) {
          ons.notification.alert(
            local["lat"] +
              " - " +
              local["long"] +
              " - " +
              local["data"] +
              " - Bateria:" +
              local["bateria"]
          );
        } else {
          ons.notification.alert("não veio nada");
        }
      } catch (error) {
        ons.notification.alert(error);
      }
    } else {
      return "notandroid";
    }
  }

  getLocalVar(_var) {
    if (localStorage.getItem(_var)) {
      return localStorage.getItem(_var);
    } else {
      return false;
    }
  }

  showAlertDialogStartService() {
    this.setState({ alertDialogStartServiceShown: true });
  }

  hideAlertDialogStartService() {
    this.setState({ alertDialogStartServiceShown: false });
  }

  async doSetStartServiceTime() {
    this.setState({ loading: true });
    const auth_token = localStorage.getItem("auth_token");
    const uid = localStorage.getItem("user_id");
    const { chamado } = this.props.location.state;
    const gravaDataInicioAtendimento = await setStartServiceTime(
      auth_token,
      uid,
      chamado.numcha
    );
    if (
      gravaDataInicioAtendimento.data.error &&
      !gravaDataInicioAtendimento.data.isLoggedIn
    ) {
      ons.notification.alert("Erro, tente novamente.");
    } else {
      localStorage.setItem("isOnService", true);
      window.location.href = "/chamados";
      //this.setState({loading:false,serviceStarted:true,alertDialogStartServiceShown:false});
    }
  }

  doLogout() {
    localStorage.clear();
    this.setState({
      isLoggedIn: false,
      loading: false,
      user_info: {
        uid: null,
        user_name: null,
        auth_token: null
      }
    });
  }

  render() {
    const {
      user_info,
      loading,
      isLoggedIn,
      chamado,
      serviceStarted,
      userMustAttach,
      hasAttachment,
      temPendenciaAtiva
    } = this.state;

    console.log(userMustAttach);
    console.log(hasAttachment);
    const isOnService = localStorage.getItem("isOnService");
    let serviceStartedButton = serviceStarted === false ? false : true;
    const canOpenPendencia = serviceStarted === false ? true : false;
    let canEndServiceButton =
      serviceStarted === false || temPendenciaAtiva === true ? true : false;
    const iconStartedButton =
      serviceStarted === false ? (
        <i className="fa fa-play"></i>
      ) : (
        <i className="fa fa-cogs"></i>
      );
    const textStartedButton = serviceStarted === false ? "Iniciar" : "Iniciado";

    if (loading) {
      return (
        <span
          className="loader_"
          style={{ width: "7em", height: "7em", marginLeft: "-2%" }}
        ></span>
      );
    }

    if (!isLoggedIn) {
      return <Redirect to="/login" />;
    }

    return (
      <Ons.Page
        renderModal={() => (
          <Ons.Toolbar>
            <div className="left">
              <Link to="/chamados">
                <Ons.ToolbarButton>
                  <Ons.Icon icon="fa-arrow-left" />
                </Ons.ToolbarButton>
              </Link>
            </div>
            <div className="center">Chamado Nº {chamado.numcha}</div>
          </Ons.Toolbar>
        )}
      >
        <div className="content" style={{ marginTop: "60px" }}>
          <Ons.Card>
            <center>
              <h4>Dados do Chamado</h4>
            </center>
            <table className="table" style={{ fontSize: "11px" }}>
              <tbody>
                <tr>
                  <td>Cliente:</td>
                  <td>
                    <b>{chamado.nomcli}</b>
                  </td>
                </tr>
                <tr>
                  <td>Data:</td>
                  <td>{chamado.datcha}</td>
                </tr>
                <tr>
                  <td>Solicitante:</td>
                  <td>{chamado.solicitante}</td>
                </tr>
                <tr>
                  <td>Setor:</td>
                  <td>{chamado.setor}</td>
                </tr>
                <tr>
                  <td>Endereço:</td>
                  <td>
                    <a href={"http://maps.google.com/?q=" + chamado.endcli}>
                      {chamado.endcli}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Fabricante:</td>
                  <td>{chamado.fabricante}</td>
                </tr>
                <tr>
                  <td>Modelo:</td>
                  <td>{chamado.modelo}</td>
                </tr>
                <tr>
                  <td>Nº Série:</td>
                  <td>{chamado.nserie}</td>
                </tr>
                <tr>
                  <td>Descrição:</td>
                  <td>{chamado.descricao}</td>
                </tr>
                <tr>
                  <td>Data despachado:</td>
                  <td>{chamado.data_despachado}</td>
                </tr>
                <tr>
                  <td>Data Recebido:</td>
                  <td>{chamado.data_recebido}</td>
                </tr>
                <tr>
                  <td>Data lido:</td>
                  <td>
                    {chamado.data_lido === false
                      ? "Agora mesmo."
                      : chamado.data_lido}
                  </td>
                </tr>
                {serviceStarted ? (
                  <tr>
                    <td>Data Início Atendimento:</td>
                    <td>{chamado.data_inicio_atendimento}</td>
                  </tr>
                ) : null}
              </tbody>
            </table>

            <hr />

            <center style={{ marginTop: "-10px" }}>
              <h4>Opções</h4>
              {temPendenciaAtiva ? (
                <h6>
                  Há uma pendência ativa. Você deve aguardar o retorno da área
                  técnica.
                </h6>
              ) : (
                ""
              )}
            </center>
            <div className="row" style={{ margin: "2px" }}>
              <button
                onClick={this.showAlertDialogStartService.bind(this)}
                className="btn btn-success btn-lg"
                disabled={serviceStartedButton}
                style={{ width: "48%" }}
              >
                {iconStartedButton} {textStartedButton}
              </button>
              <button
                className="btn btn-secondary btn-lg pull-right"
                style={{ width: "48%" }}
                disabled="true"
              >
                <i className="fa fa-history"></i> Histórico
              </button>
            </div>
            <div className="row" style={{ margin: "2px", marginTop: "5px" }}>
              <Link
                to={{
                  pathname: `/pendencias/${chamado.numcha}`,
                  state: {
                    chamado: chamado
                  }
                }}
                style={{ color: "black" }}
              >
                <button
                  className="btn btn-secondary btn-lg"
                  style={{ width: "48%" }}
                  disabled={canOpenPendencia}
                >
                  <i className="fa fa-tasks"></i> Pendências
                </button>
              </Link>
              <Link
                to={{
                  pathname: `/finalizar/${chamado.numcha}`,
                  state: {
                    chamado: chamado,
                    hasAttachment: hasAttachment,
                    userMustAttach: userMustAttach
                  }
                }}
                style={{ color: "black" }}
              >
                <button
                  className="btn btn-danger btn-lg pull-right"
                  style={{ width: "48%" }}
                  disabled={canEndServiceButton}
                >
                  <i className="fa fa-stop"></i> Finalizar
                </button>
              </Link>
            </div>
          </Ons.Card>
        </div>

        <Ons.AlertDialog
          isOpen={this.state.alertDialogStartServiceShown}
          isCancelable={false}
        >
          <div className="alert-dialog-title">Iniciar Atendimento</div>
          <div className="alert-dialog-content">Tem Certeza?</div>
          <div className="alert-dialog-footer">
            <button
              onClick={this.hideAlertDialogStartService.bind(this)}
              className="alert-dialog-button"
            >
              Cancelar
            </button>
            <button
              onClick={this.doSetStartServiceTime.bind(this)}
              className="alert-dialog-button"
            >
              Sim
            </button>
          </div>
        </Ons.AlertDialog>
      </Ons.Page>
    );
  }
}
