import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from '../views/Home';
import Login from '../views/Login';
import Chamados from '../views/Chamados';
import Chamado from '../views/Chamado';
import Pendencias from '../views/Pendencias';
import Finalizar from '../views/Finalizar';
import Despachar from '../views/Despachar';

class App extends Component {
  
  render() {

    return (
      <div className="App">
        <Router>
          <div>
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/login" component={Login}></Route>
            <Route exact path="/chamados" component={Chamados}></Route>
            <Route exact path="/despachar" component={Despachar}></Route>
            <Route path="/chamado/:id" component={Chamado}></Route>
            <Route path="/pendencias/:id" component={Pendencias}></Route>
            <Route path="/finalizar/:id" component={Finalizar}></Route>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
