import React, { Component } from "react";
import ons from "onsenui";
import * as Ons from "react-onsenui";
import ReactDOM from "react-dom";
import { Redirect } from "react-router";
import "onsenui/css/onsenui.css";
import { Link } from "react-router-dom";
import "onsenui/css/onsen-css-components.css";
import "bootstrap/dist/css/bootstrap.css";
import { checkSession, findAll } from "../containers/Api";

class Chamados extends Component {
  state = {
    isLoggedIn: false,
    user_info: {
      uid: null,
      user_name: null,
      auth_token: null
    },
    loading: true,
    chamados: null
  };

  async componentDidMount() {
    document.title = "Chamados - ConectaAppTech";
    const isLoggedIn = this.getLocalVar("auth_token");
    if (!isLoggedIn) {
      this.setState({ isLoggedIn: false, loading: false });
    } else {
      const auth_token = localStorage.getItem("auth_token");
      const uid = localStorage.getItem("user_id");
      const user_name = localStorage.getItem("user_name");
      const _checkSession = await checkSession(auth_token, uid);
      if (_checkSession.data.error && !_checkSession.data.isLoggedIn) {
        this.doLogout();
      }

      //busca chamados
      const buscaChamados = await findAll(uid, auth_token);
      if (buscaChamados.data.isLoggedIn.error) {
        this.doLogout();
      }

      const _chamados = buscaChamados.data.error
        ? false
        : buscaChamados.data.chamados;

      this.isGpsEnabled();

      //persiste response
      this.setState({
        loading: false,
        isLoggedIn: true,
        chamados: _chamados,
        user_info: { uid: uid, user_name: user_name, auth_token: auth_token }
      });
    }
  }

  isGpsEnabled() {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf("android") > -1;
    if (isAndroid) {
      try {
        if (!window.isGpsEnabled()) {
          try {
            ons.notification.alert({
              message: "Localização desabilitada, ative.",
              title: "ConectaAppTech",
              buttonLabel: "OK",
              animation: "default", // or 'none'
              callback: () => {
                window.openGpsConfig();
              }
            });
          } catch (error) {
            ons.notification.alert("Erro: " + error);
          }
        }
      } catch (error) {
        return false;
      }
    }
  }

  getLocalVar(_var) {
    if (localStorage.getItem(_var)) {
      return localStorage.getItem(_var);
    } else {
      return false;
    }
  }

  doLogout() {
    localStorage.clear();
    this.setState({
      isLoggedIn: false,
      loading: false,
      user_info: { uid: null, user_name: null, auth_token: null }
    });
  }

  getImgSla(situacao) {
    if (situacao === "1") {
      return "../img/sla_green.png";
    } else if (situacao === "2") {
      return "../img/sla_orange.png";
    } else if (situacao === "3") {
      return "../img/sla_red.gif";
    }
  }

  doAlert(index) {
    ons.notification.alert("MAhOie o index é: " + index);
  }

  render() {
    const { user_info, loading, isLoggedIn, chamados } = this.state;

    if (loading) {
      return (
        <span
          className="loader_"
          style={{ width: "7em", height: "7em", marginLeft: "-2%" }}
        />
      );
    }

    if (!isLoggedIn) {
      return <Redirect to="/login" />;
    }

    if (!chamados) {
      return (
        <Ons.Page
          renderModal={() => (
            <Ons.Toolbar>
              <div className="left">
                <Link to="/">
                  <Ons.ToolbarButton>
                    <Ons.Icon icon="fa-arrow-left" />
                    Home
                  </Ons.ToolbarButton>
                </Link>
              </div>
              <div className="center">ConectaAppTech</div>
            </Ons.Toolbar>
          )}
        >
          <div className="content" style={{ marginTop: "60px" }}>
            <Ons.Card>
              <center>
                <h3>Nenhum Chamado Disponível.</h3>
              </center>
            </Ons.Card>
          </div>
        </Ons.Page>
      );
    }

    return (
      <Ons.Page
        renderModal={() => (
          <Ons.Toolbar>
            <div className="left">
              <Link to="/">
                <Ons.ToolbarButton>
                  <Ons.Icon icon="fa-arrow-left" />
                  Home
                </Ons.ToolbarButton>
              </Link>
            </div>
            <div className="center">ConectaAppTech</div>
          </Ons.Toolbar>
        )}
      >
        <div className="content" style={{ marginTop: "60px" }}>
          {chamados.map((chamado, index) => (
            <Link
              key={index}
              to={{
                pathname: `/chamado/${chamado.numcha}`,
                state: {
                  chamado: chamados[index]
                }
              }}
              style={{ color: "black" }}
            >
              <Ons.Card>
                <div style={{ fontSize: "11px" }}>
                  <div
                    className="d-flex w-50 justify-content-between"
                    style={{ margin: "0px" }}
                  >
                    <h5 className="mb-1">
                      <b>
                        {chamado.numcha} - {chamado.nomcli}
                      </b>
                    </h5>
                  </div>
                  <p className="mb-1" style={{ margin: "2px 1px 2px" }}>
                    {chamado.descricao}
                  </p>
                  <small>
                    {chamado.data_inicio_atendimento === false ? (
                      ""
                    ) : (
                      <i style={{ fontSize: "16px" }} className="fa fa-cogs" />
                    )}
                    {chamado.temPendenciaAtiva === true ? (
                      <i
                        style={{ fontSize: "16px", marginLeft: "2px" }}
                        className="fa fa-tasks"
                      />
                    ) : null}
                    {chamado.data_lido === false ? (
                      <i
                        title="Não Lido"
                        style={{ fontSize: "16px", color: "green" }}
                        className="fa fa-certificate"
                      />
                    ) : (
                      ""
                    )}{" "}
                    Data Chamado: {chamado.datcha}
                    <span className="pull-right">
                      <img width="14" src={this.getImgSla(chamado.situacao)} />
                    </span>
                  </small>
                </div>
              </Ons.Card>
            </Link>
          ))}
        </div>
      </Ons.Page>
    );
  }
}

export default Chamados;
