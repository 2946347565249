import React, { Component } from 'react';
import {Redirect} from 'react-router';
import ons, { createAlertDialog } from 'onsenui';
import * as Ons from 'react-onsenui';
import 'bootstrap/dist/css/bootstrap.css';
import 'onsenui/css/onsenui.css';
import { Link } from 'react-router-dom'
import 'onsenui/css/onsen-css-components.css';
import {checkSession,getPendencias,createPendencia} from '../containers/Api';
import {
    Form
  } from 'reactstrap';

export default class Pendencias extends Component {
    
    state = {
        isLoggedIn: false,
        loading: true,
        chamado: null,
        serviceStarted: false,
        pendencias: null,
        user_info : {
            uid: null,
            user_name: null,
            auth_token: null,
        },
        index: 0,
        createPendencia: false,
        descricao: null,
        cpb: 0,
        ccolor: 0,
        nserie: null,
        setor: null
    }

    async componentDidMount(){
        
        const isLoggedIn = this.getLocalVar("auth_token");
        if(!isLoggedIn){
            this.setState({isLoggedIn:false,loading:false});
        }else{
            const auth_token = localStorage.getItem("auth_token");
            const uid = localStorage.getItem("user_id");
            const user_name = localStorage.getItem("user_name");
            const _checkSession = await checkSession(auth_token,uid);
            if(_checkSession.data.error && !_checkSession.data.isLoggedIn)
            {
                this.doLogout();
            }
        
            try {
                const {chamado} = this.props.location.state;
                document.title = "Pendências Chamado Nº"+chamado.numcha;
                
                //pendenciasOperations
                let chamado_pendencias = await getPendencias(auth_token,uid,chamado.numcha);
                chamado_pendencias = chamado_pendencias.data;
                this.isGpsEnabled();
                this.setState({loading:false,pendencias:chamado_pendencias,setor:chamado.setor,nserie:chamado.nserie,isLoggedIn:true,serviceStarted:chamado.data_inicio_atendimento,chamado:chamado,user_info:{uid:uid,user_name:user_name,auth_token:auth_token}});
            }catch (error) {
                this.doLogout(); 
            }
        }
    }

    isGpsEnabled() {
        const ua = navigator.userAgent.toLowerCase();
        const isAndroid = ua.indexOf("android") > -1;
        if (isAndroid) {
          try {
            if (!window.isGpsEnabled()) {
              try {
                ons.notification.alert({
                  message: "Localização desabilitada, ative.",
                  title: "ConectaAppTech",
                  buttonLabel: "OK",
                  animation: "default", // or 'none'
                  callback: () => {
                    window.openGpsConfig();
                  }
                });
              } catch (error) {
                ons.notification.alert("Erro: " + error);
              }
            }
          } catch (error) {
            return false;
          }
        }
      }

    getLocalVar(_var){
        if(localStorage.getItem(_var)){
            return localStorage.getItem(_var);
        }else{
            return false;
        }
    }

    doLogout(){
        localStorage.clear();
        this.setState({isLoggedIn:false,loading:false,user_info : { uid: null, user_name: null, auth_token: null }} );
    }

    onSubmit = async (e) =>{
        e.preventDefault();
        if (
            this.state.descricao === "" ||
            this.state.cpb === "" ||
            this.state.ccolor === "" ||
            this.state.nserie === "" ||
            this.state.setor === ""
        ){
            ons.notification.alert("Por favor, preencha todos os dados.");
        }else{
            if(window.confirm("Tem certeza?"))
            {
                this.setState({loading:true});
                const auth_token = localStorage.getItem("auth_token");
                const uid = localStorage.getItem("user_id");
                const {chamado} = this.props.location.state;
                const salvaPendencia = await createPendencia(auth_token, uid, chamado.numcha, this.state.descricao,this.state.cpb,this.state.ccolor,this.state.nserie,this.state.setor);
                if(salvaPendencia.data.error){
                    ons.notification.alert({message: 'Erro ao salvar pendência. Tente novamente',
                    title: 'ConectaAppTech',
                    buttonLabel: 'OK',
                    animation: 'default', // or 'none'
                    callback: () => {
                        window.location.reload();
                    }});
                }else{
                    ons.notification.alert({message: salvaPendencia.data.msg,
                    title: 'ConectaAppTech',
                    buttonLabel: 'OK',
                    animation: 'default', // or 'none'
                    callback: () => {
                        window.location.href = '/chamados';
                    }});
                }
            }
        }
    }

    handleFocus(event) {
        event.target.select();
    }

    handleChange = e => {
    this.setState({
        ...this.state,
        [e.target.name]: e.target.value
    });
    };

    renderFormCreateTask(numcha){
        const {chamado} = this.state;
        return (
            <div>
                <Ons.Card>
                    <h4>Criar Pendência</h4>
                    <h6>Descreva a Pendência e preencha os dados.</h6>
                    <hr />
                    <Form>
                        
                        <div className="form-group">
                            <label for="descricaopend">Descrição</label>
                            <textarea className="form-control" rows="5" name="descricao" onChange={this.handleChange}></textarea>
                        </div>
                        <div className="form-group">
                            <label for="cpb">Contador PB</label>
                            <input className="form-control" type="number" name="cpb" defaultValue="0" min="0" onFocus={this.handleFocus} onChange={this.handleChange} />
                        </div>
                        <div className="form-group">
                            <label for="ccolor">Contador Color</label>
                            <input className="form-control" type="number" name="ccolor" defaultValue="0" min="0" onFocus={this.handleFocus} onChange={this.handleChange} />
                        </div>
                        <div className="form-group">
                            <label for="ccolor">Setor</label>
                            <input className="form-control" type="text" name="setor" onChange={this.handleChange} defaultValue={chamado.setor} />
                        </div>
                        <div className="form-group">
                            <label for="ccolor">Nº Série</label>
                            <input className="form-control" type="text" name="nserie" onChange={this.handleChange} defaultValue={chamado.nserie} />
                        </div>

                        <hr />
                        <center>
                            <Ons.Button type="submit" className="btn-success" onClick={this.onSubmit}>Enviar</Ons.Button>
                            <Ons.Button style={{marginLeft:'5px'}} onClick={this.cancelCreatePendencia.bind(this)}>Cancelar</Ons.Button>
                        </center>
                    </Form>
                </Ons.Card>
            </div>
        )
    }

    checkedItem(){
        ons.notification.alert("oi");
    }

    createPendencia(){
        this.setState({createPendencia:true});
    }

    cancelCreatePendencia(){
        this.setState({createPendencia:false});
    }
        
    render(){

        const {user_info,createPendencia,loading,isLoggedIn,chamado,pendencias,serviceStarted} = this.state;
        
        if(loading)
        {
            return <span className="loader_" style={{width:'7em',height:'7em',marginLeft:'-2%'}}></span>;
        }

        if(!isLoggedIn){
           return <Redirect to='/login' />
        }

        return(
            <Ons.Page renderModal={() =>
                <Ons.Toolbar>
                    <div className="left">
                    <Link to={{
                                pathname:`/chamado/${chamado.numcha}`,
                                state: {
                                    chamado: chamado
                                }
                            }}
                        style={{color:'black'}}>
                            <Ons.ToolbarButton>
                                <Ons.Icon icon="fa-arrow-left" />
                            </Ons.ToolbarButton>
                        </Link>
                    </div>
                    <div className="center">
                        <span style={{fontSize:'16px'}}>Pendências Chamado Nº {chamado.numcha}</span>
                    </div>
                </Ons.Toolbar>
            }>
            <div className="content" style={{marginTop:'60px'}}>
              {(pendencias.error)
                ?
                <Ons.Card>
                    <center>
                        <h4>{pendencias.error_msg}</h4>
                    </center>
                    <Ons.Button modifier="large--cta" onClick={this.createPendencia.bind(this)}>
                        Criar Pendência
                    </Ons.Button>
                </Ons.Card>
                :
                <div>
                    <Ons.Card>
                        <center>
                            <h4>Existem {pendencias.pendencias.length} Pendência(s) cadastradas.</h4>
                        </center>
                        <Ons.Button modifier="large--cta" onClick={this.createPendencia.bind(this)}>
                            Incluir Pendência
                        </Ons.Button>
                    </Ons.Card>
                    {pendencias.pendencias.map((pendencia, index) => 
                    (
                        /*<Link key={index} to={{
                            pathname:`/pendencia/${pendencia.numcha}/${pendencia.id}`,
                            state: {
                                pendencia: pendencias.pendencias[index]
                            }
                        }}
                        style={{color:'black'}}>*/
                            <Ons.Card>
                                <div style={{fontSize:'11px'}}>
                                    <div className="d-flex w-50 justify-content-between" style={{margin:'0px'}}>
                                        <h5 className="mb-1"><b>Pendência ID: {pendencia.id}</b></h5>
                                    </div>
                                    <p className="mb-1" style={{margin:'2px 1px 2px'}}>
                                    Descrição: {pendencia.descricao}
                                    </p>
                                    <p className="mb-1" style={{margin:'2px 1px 2px'}}>
                                    Status: {pendencia.status}                                    
                                    </p>
                                    <p className="mb-1" style={{margin:'2px 1px 2px'}}>
                                    Data Pendência: {pendencia.createdon}
                                    </p>
                                </div>
                            </Ons.Card>
                        //</Link>
                    ))}
                </div>
              }
              {(createPendencia)? this.renderFormCreateTask(chamado.numcha) : null}
            </div>
            </Ons.Page>
        )
    }
}