import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import conectaLogo from '../img/logo-conecta.png';
import {Redirect} from 'react-router';
import {login} from '../containers/Api';
import {
  Form
} from 'reactstrap';

class Login extends Component {
  
  state = {
    isLoggedIn : false,
    errorMsg: null,
  }

  componentDidMount(){
    document.title = 'Login ConectaAppTech';
  }
  
  onSubmit = async (e) =>{
    e.preventDefault();
    if(this.uLogin.value.length > 0 && this.uPass.value.length > 0)
    {
      this.setState({errorMsg:null});
      const loginResult = await login(this.uLogin.value,this.uPass.value);
      if(!loginResult.data.error)
      {
        localStorage.setItem("auth_token", loginResult.data.user.auth_token);
        localStorage.setItem("user_id", loginResult.data.uid);
        localStorage.setItem("user_name", loginResult.data.user.name);
        this.setState({isLoggedIn:true});
      }else{
        const errorMsg = <div className="alert alert-danger">Usuário e/ou senha inválidos.</div>;
        this.uPass.value = '';
        this.uLogin.value = '';
        this.uLogin.focus();
        this.setState({errorMsg:errorMsg});
      }
    }else{
      const errorMsg = <div className="alert alert-danger">Informe o login e senha.</div>;
      this.setState({errorMsg:errorMsg});
    }
  }

  
  
  render() {

    const {isLoggedIn, errorMsg} = this.state;

    if(isLoggedIn){
      return <Redirect to='/' />;
    }

    return (
      <div className="container">
        <div className="row" style={{marginTop:'60px'}}>
          <div className="col-md-4 col-md-offset-4">
            <div className="login-panel panel panel-default">
              <div className="panel-heading" align="center">
                  <img src={conectaLogo} width="170" alt="Logo" />
              </div>
              <div className="panel-body">
                <Form>
                  <fieldset>
                    {errorMsg}
                    <div className="form-group">
                        <input className="form-control" placeholder="Login" name="uLogin" ref={(uLogin) => { this.uLogin = uLogin; }} type="text" />
                    </div>
                    <div className="form-group">
                      <input className="form-control" placeholder="Senha" name="uPass" ref={(uPass) => { this.uPass = uPass; }} type="password" />
                    </div>
                    <button type="submit" onClick={this.onSubmit} className="btn btn-lg btn-success btn-block">Login</button>
                  </fieldset>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
